import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/anHuaHomePage/index.vue'),
        meta: {
          title: '首页'
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
