import { removeTokenCookie, setTokenCookie } from '@/utils/cookies'
import { getCurrent } from '@/api/users-service'
import { getOptions } from '@/api/option-service'
import store from '@/store/index.js'
const getModule = {
  // 设置token
  setToken (token) {
    setTokenCookie('Bearer ' + token)
  },
  // 用户退出 删除token
  Logout () {
    removeTokenCookie()
    // 删除用户信息
    store.commit('optionsUser/setuserInfo', {})
    // 删除字典信息
    store.commit('optionsShare/setShareinfo', {})
  },
  // 获取用户相关的数据
  async getUser (value = false) {
    // token是否一致
    store.dispatch('cookies/setAccessToken')

    // 获取用户信息
    const userInfo = store.getters['optionsUser/getuserInfoArr']
    if (value || !userInfo || userInfo.length === 0) {
      // 设置用户信息
      const res = await getCurrent()
      store.commit('optionsUser/setuserInfo', res.data)
    }
    // 获取字典
    const shareinfo = store.getters['optionsShare/getShareinfoArr']
    if (!shareinfo || shareinfo.length === 0) {
      // 获取字典信息
      getOptions().then((res) => {
        store.commit('optionsShare/setShareinfo', res.data)
      })
    }
  }
}
export const UserModule = getModule
