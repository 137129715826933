import Vue from 'vue'
import Vuex from 'vuex'
import cookies from '@/store/modules/cookies'
import optionsUser from '@/store/modules/optionsUser'
import optionsShare from '@/store/modules/optionsShare'
import loginPopupSwitch from '@/store/modules/loginPopupSwitch'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // 用户信息
    optionsUser,
    // 字典信息
    optionsShare,
    // cookies信息包含token,
    cookies,
    // 登录弹窗的开关
    loginPopupSwitch
  }
})
