import request from '@/utils/request'
import requestlogein from '@/utils/requestlogein'
/**
 * 获取手机验证码
 * @param mobile
 * @returns {AxiosPromise}
 */
export const postLoginCaptcha = (params) =>
  request({
    url: '/user/code/sms',
    method: 'get',
    params
  })
/**
 * 获取监管列表 短信
 * @param cellphone
 * @param captcha
 * @returns {AxiosPromise}
 */
export const getAdministerOptions = (cellphone, captcha) =>
  request({
    url: `/administration/user/getAdministUnitListByUserId/${cellphone}/${captcha}`,
    method: 'get'
  })
/**
* 获取监管列表 密码
* @param cellphone
* @param captcha
* @returns {AxiosPromise}
*/
export const getAdministUnitListByAccount = (data) =>
  request({
    url: '/administration/user/getAdministUnitListByAccount',
    method: 'POST',
    data
  })
/**
* 用户登录成功前，根据账号获取企业列表
* @param cellphone
* @param captcha
* @returns {AxiosPromise}
*/
export const getEnterpriseUnitListBvAccount = (data) =>
  request({
    url: '/enterprise/user/getEnterpriseUnitListByAccount',
    method: 'POST',
    data
  })
/**
 * 获取企业列表
 * @param cellphone
 * @param captcha
 * @returns {AxiosPromise}
 */
export const getEnterpriseOptions = (cellphone, captcha) =>
  request({
    url: `/enterprise/user/getEnterpriseUnitListByUserId/${cellphone}/${captcha}`,
    method: 'get'
  })

/**
* 获取获取token 短信
* @param data
* @returns {AxiosPromise}
*/
export const postLogin = (data) =>
  request({
    url: '/auth/oauth/login',
    method: 'post',
    data
  })

/**
* 获取获取token 密码
* @param data
* @returns {AxiosPromise}
*/
export const passwordLogin = (data) =>
  request({
    url: '/auth/password/login',
    method: 'post',
    data
  })

/**
 * 退出登入
 * @param data
 * @returns {AxiosPromise}
 */
export const logoutLogin = () => {
  return request({
    url: '/users/logout',
    method: 'post'
  })
}

/**
 * 获取登入用户信息
 * @param data
 * @returns {AxiosPromise}
 */
export const getCurrent = () => {
  return request({
    url: '/user/getMyLoginDetail',
    method: 'get'
  })
}

/**
 * 用户退出或注销
 * @param data
 * @returns {AxiosPromise}
 */
export const exit = (data) =>
  requestlogein({
    url: '/dologout',
    method: 'post',
    data
  })
/**
 * 免登入
 * @param data
 * @returns {AxiosPromise}
 */
export const yyadmin = (data) =>
  request({
    url: '/auth/authentication/yyadmin',
    method: 'post',
    data,
    params: data
  })

/**
* 绑定微信
* @param data
* @returns {AxiosPromise}
*/
export const weChatBinding = (data) =>
  request({
    url: '/user/weChatBinding',
    method: 'post',
    data
  })

/**
* 微信登录
* @param data
* @returns {AxiosPromise}
*/
export const wechatLogin = (data) =>
  request({
    url: '/auth/wechat/login',
    method: 'post',
    data
  })

/**
* 用户登录成功前，根据微信Code获取企业列表
* @param data
* @returns {AxiosPromise}
*/
export const getEnterpriseUnitListByWeChat = (data) =>
  request({
    url: '/enterprise/user/getEnterpriseUnitListByWeChat',
    method: 'post',
    params: data
  })

/**
* 用户微信登录成功前，根据微信Code获取行政单位列表
* @param data
* @returns {AxiosPromise}
*/
export const getAdminUnitListByWeChat = (data) =>
  request({
    url: '/administration/user/getAdminUnitListByWeChat',
    method: 'post',
    params: data
  })

/**
* App扫码登录
* @param data
* @returns {AxiosPromise}
*/
export const oauthQrCodeLogin = (data) =>
  request({
    url: '/auth/qrCode/login',
    method: 'post',
    data
  })
