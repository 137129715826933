<template>
  <el-container class='my-container'>
        <el-header>
            <HeadBar></HeadBar><!-- 头部-->
        </el-header>
        <el-container>
            <el-main>
                <AppMain></AppMain><!--右侧显示区域-->
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import HeadBar from '@/layout/components/header.vue'
import AppMain from '@/layout/components/main.vue'
export default {
  name: 'FrameIndex',
  components: {
    AppMain,
    HeadBar
  },
  computed: {
    single () {
      return this.$route.path
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.my-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.el-header {
  z-index: 999;
  padding: 0;
  height: 50px !important;
  box-shadow: 0px 3px 6px rgba(153, 153, 153, 0.16);
}

.black .el-header {
  box-shadow: 0px 3px 6px rgba(153, 153, 153, 0.16);
}

.el-main {
  height: calc(100vh - 50px);
  flex: 1;
  padding: 0;
  overflow-y: auto;
  display: flex;
  position: relative;
}
</style>
