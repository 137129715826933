import router from '@/router/index.js'
import { UserModule } from '@/store/modules/user'
import { Message } from 'element-ui'
import { getTokenCookie } from '@/utils/cookies'
import versionTood from '@/versionUpdate.js'
router.beforeEach(async (to, from, next) => {
  window.name = 'main'
  Message.closeAll()
  // 判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion()
  // 第三方登录
  if (to.path.includes('/access_token')) {
    const firstQuarter = to.path.slice(14)
    UserModule.setToken(firstQuarter.split('&')[0])

    window.parent.window.location.href = window.location.protocol + '//' + window.location.host + '/'
  } else if (getTokenCookie()) {
    UserModule.getUser(false)
    next()
  } else {
    if (to.path.indexOf('/login') > -1) {
      next()
    } else {
      window.location.href = process.env.VUE_APP_IFRAME_API + window.location.protocol + '//' + window.location.host + '/'
      next()
    }
  }
})
