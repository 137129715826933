import { getTokenCookie } from '@/utils/cookies'
import { UserModule } from '@/store/modules/user'
export default {
  namespaced: true,
  actions: {
    // 获取token对比
    async setAccessToken (context) {
      const token = getTokenCookie()
      // 如果没有给他设置
      if (!context.state.access_token) {
        context.commit('setAccessToken', token)
      } else if (context.state.access_token !== token) {
        context.commit('setAccessToken', token)
        await UserModule.getUser(true)
      }
    }
  },
  mutations: {
    setAccessToken (state, value) {
      state.access_token = value
    }
  },
  state: {
    access_token: ''
  }
}
