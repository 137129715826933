export default {
  namespaced: true,
  state: {
    switch: false
  },
  mutations: {
    resizeWidth (state, data) {
      state.switch = data
    }
  },
  getters: {
    getSwitch (state) {
      return state.switch
    }
  }
}
