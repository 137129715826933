<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted () {
    window.onresize = () => {
      this.clientWidthResize()
    }
  },
  methods: {
    // 获取屏幕宽度放到Vuex里
    clientWidthResize () {
      this.$store.commit('tool/resizeWidth', Number(document.documentElement.clientWidth))
    }
  }
}
</script>

<style>
#app {
  font-family: Source Han Sans SC, Avenir, Helvetica, Arial, sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

/* 单行组合写法溢出隐藏 */
.myhide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
