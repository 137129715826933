<template>
  <div class="theme-style blue">
    <div class="imgandtext-style">
      <img
        style="width: auto; height: 30px"
        :src="require('@/assets/layout/anhua.png')"
      />
      <div class="typeface">
        {{ "安化县智慧住建数字化管理平台" }}
      </div>
    </div>
    <div class="theme-rigth">
      <!-- <div class="identity">{{ userInfo?.unitName }}</div>
      <el-divider direction="vertical" class="main-string"></el-divider> -->
      <div class="user" @mouseenter="close = true" @mouseleave="close = false">
        <div class="el-dropdown-style">
          <div class="menu-top">
            <div v-if="userInfo.headImageUrl">
              <el-image
                style="height: 30px; width: 30px; border-radius: 50%"
                :src="userInfo.headImageUrl"
                fit="cover"
              />
            </div>
            <div v-if="!userInfo.headImageUrl">
              <img
                style="height: 30px; width: 30px; border-radius: 50%"
                src="@/assets/homePage/user-img1.png"
              />
            </div>
          </div>
          {{ userInfo.realname }}
          <img
            style="margin-left: 10px"
            src="@/assets/layout/pull-down.png"
          />
        </div>
        <div class="close" @click="logout" v-show="close">
          <i class="el-icon-switch-button icon"></i>
          退出登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTokenCookie } from '@/utils/cookies'
import { UserModule } from '@/store/modules/user'
import { exit } from '@/api/users-service'
import { mapState } from 'vuex'
export default {
  name: 'HeaDer',
  props: {},
  components: {},
  data () {
    return {
      close: false,
      subject: false
    }
  },
  computed: {
    ...mapState('optionsUser', ['userInfo'])
  },
  methods: {
    // 退出
    async logout () {
      if (getTokenCookie() == null) {
        window.location.href =
          process.env.VUE_APP_IFRAME_API +
          window.location.protocol +
          '//' +
          window.location.host +
          '/'
      }
      await exit({ accessToken: getTokenCookie().slice(7) })
        .then(() => {
          UserModule.Logout()
        })
        .catch((error) => {
          console.log(error)
        })
      window.location.href =
        process.env.VUE_APP_IFRAME_API +
        window.location.protocol +
        '//' +
        window.location.host +
        '/'
    }
  }
}
</script>

<style lang="scss" scoped>
.blue {
  background-color: #1972c6;
  color: #fff;
}

.white {
  background-color: #fff;
  color: #333333;
}

.black {
  background-color: #181b1e;
  color: #ffffff;
}

.theme-style {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imgandtext-style {
  height: 50px;
  font-size: 18px;
  font-family: SimSun;
  font-weight: 400;
  display: flex;
  margin-left: 1vw;
  align-items: center;
}

.typeface {
  margin-left: 10px;
}

.el-dropdown-style {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  width: 150px;
}

.theme-rigth {
  display: flex;
  align-items: center;
}

.identity {
  margin-right: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
}

.user {
  position: relative;
  cursor: pointer;
}

.close {
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue .close {
  background-color: #1972c6;
}

.icon {
  margin-right: 10px;
  font-size: 18px;
}

.subject {
  height: 50px;
  cursor: pointer;
}

.subject-text {
  width: 106px;
  height: 34px;
  border: 1px solid #e4e4e4;
  opacity: 1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clothing {
  margin-right: 10px;
}

.subject-box {
  height: 100%;
  display: flex;
  align-items: center;
}

.item-box {
  background-color: #1972c6;
  box-shadow: 0px 3px 10px rgba(25, 114, 198, 0.1);
  opacity: 1;
  border-radius: 0px 0px 5px 5px;
  font-size: 14px;
}

.item {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-string {
  margin-left: 20px;
  height: 2em;
}

.transverse-line {
  margin: 0 auto;
  width: 80%;
}

.ispicht {
  color: #d8c40e;
}

.white .close {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(25, 114, 198, 0.1);
  opacity: 1;
  border-radius: 0px 0px 5px 5px;
  color: #ef5a5a;
}

.black .close {
  background: #1e2125;
  box-shadow: 0px 3px 10px rgba(25, 114, 198, 0.2);
  opacity: 1;
  border-radius: 0px 0px 5px 5px;
  color: #ef5a5a;
}

.white .item-box {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(25, 114, 198, 0.1);
  opacity: 1;
  border-radius: 0px 0px 5px 5px;
}

.black .item-box {
  background: #1e2125;
  box-shadow: 0px 3px 10px rgba(25, 114, 198, 0.2);
  opacity: 1;
  border-radius: 0px 0px 5px 5px;
}

.white .ispicht,
.black .ispicht {
  color: #1890ff;
}

.black .subject-text {
  border: 1px solid #585858;
}

.white .transverse-line {
  background-color: #eeeeee;
}

.black .transverse-line {
  background-color: #585858;
}

.white .main-string {
  background-color: #eeeeee;
}

.black .main-string {
  background-color: #313131;
}
</style>
