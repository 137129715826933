// 引入ElementUI组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import plugins from '@/components'
// import common from '@/mixin/common'
import * as util from '@/utils/util'
// // 全局初始化样式
import 'normalize.css'
import '@/styles/index.scss'

const MyPlugin = {
  install: function (Vue) {
    // 全局注册一个宽度
    Vue.prototype.labelWidth = '120px'
    // 引入ui框架
    Vue.use(ElementUI)
    // 全局注册一些组件
    // Vue.use(plugins)
    // 全局混入
    // Vue.mixin(common)
    // 工具函数
    Vue.prototype.util = util
  }
}
export default MyPlugin
